@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../src/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto_Slab";
  src: url("../src/assets/fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto_Slab";
  src: url("../src/assets/fonts/RobotoSlab-Black.ttf") format("truetype");
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: "Roboto_Slab";
  src: url("../src/assets/fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
